import { Item, ItemName } from "@components/lists/Lists.shared.style";
import styled from "styled-components";

export const PreText = styled.div`
  padding-bottom: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
`;

export const TitleContainer = styled.div`
  padding-right: 24px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Line = styled.div`
  background: #5a5a5a;
  width: 100%;
  height: 1px;
  margin: 4px 0 0 0;
`;

export const Wrapper = styled.div`
  ${ItemName} {
    font-size: 13px;
  }

  ${Item} div {
    font-size: 12px;
  }

  @media ${(props) => props.theme.device.xl} {
    padding: 16px 8px 0px;
    background: #343434;
  }
`;
