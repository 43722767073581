import { Panel } from "@components/core/Panel";
import { ReleasesList } from "@components/lists";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Release } from "@models/release";
import { device } from "@styles/theme";
import { Footer, Line, Wrapper } from "./TopReleases.style";

interface Props {
	title: React.ReactNode[] | React.ReactNode | JSX.Element | string;
	footer?: React.ReactNode[] | React.ReactNode | JSX.Element | string;
	isPanelList?: boolean;
	releases?: Release[];
	dataTestId?: string;
	location?: string;
}

const TopReleases: React.FC<Props> = ({
	title,
	footer,
	isPanelList,
	releases = [],
	dataTestId,
	location,
}) => {
	const isXl = useMediaQuery({ query: device.xl });
	return (
		<Wrapper>
			<Panel title={title}>
				<Line />
				<ReleasesList
					location={location}
					releases={releases}
					direction={isPanelList ? "row" : "column"}
					forceHover={isXl}
					isPanelList={isPanelList}
					showNumbers
					showArtwork
					dataTestId={dataTestId}
				/>
				<Footer>{footer}</Footer>
			</Panel>
		</Wrapper>
	);
};

export default TopReleases;
