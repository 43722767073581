import styled from "styled-components";

export const Artists = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.blue[500].accent};
`;
